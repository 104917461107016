import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { User, Phone, X } from 'lucide-react';
import logoTempo from '../assets/logo_tempo.png';
import logoUpgrade from '../assets/logo_upgrade.png';
import logoSky from '../assets/logo_sky.png';

const PreReservationPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [isRegistered, setIsRegistered] = useState(false);

  const validateName = (value) => {
    const nameRegex = /^[A-Za-z가-힣\d]{3,8}$/;
    return nameRegex.test(value);
  };

  const handleReservation = async (e) => {
    e.preventDefault();
    setNameError('');
    setPhoneError('');

    if (!validateName(name)) {
      setNameError('이름은 3~8자의 한글, 영문, 숫자만 가능합니다.');
      return;
    }

    if (phone.length < 10 || phone.length > 15) {
      setPhoneError('전화번호는 10~15자리여야 합니다.');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/pre_reservations/`, {
        user_name: name,
        mobile_number: phone
      });
      if (response.status === 201) {
        setShowModal(false);
        setShowConfirmModal(true);
        setIsRegistered(true);
        // localStorage에 신청 상태 저장
        localStorage.setItem('preReservationRegistered', 'true');
      }
    } catch (error) {
      console.error('예약 중 오류 발생:', error);
    }
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength <= 3) return phoneNumber;
    if (phoneNumberLength <= 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 7)}-${phoneNumber.slice(7, 11)}`;
  };

  const handlePhoneChange = (e) => {
    const formattedValue = formatPhoneNumber(e.target.value);
    setPhone(formattedValue.slice(0, 15));
  };

  // 컴포넌트 마운트 시 localStorage 확인
  useEffect(() => {
    const isAlreadyRegistered = localStorage.getItem('preReservationRegistered');
    if (isAlreadyRegistered === 'true') {
      setIsRegistered(true);
    }
  }, []);

  const handleButtonClick = () => {
    window.location.href = 'https://skyfuturemath.com/';
  };

  return (
    <div className="min-h-screen bg-[#4ABC85] flex flex-col items-center justify-center p-4">
      <div className="mt-[53px]" />
      <div className="flex flex-col items-center">
        <span className="text-[#FAEECE] text-2xl font-extrabold">고1 중간고사부터</span>
        <span className="text-white text-2xl font-extrabold">초격차를 만드는 비결</span>
      </div>
      <div className="mt-[7px]" />
      <span className="text-white text-xs font-thin">
        변화하는 교육과정, 수학을 업그레이드 하다
      </span>
      <div className="mt-[31px]" />
      <img src={logoUpgrade} alt="Logo Upgrade" className="w-[192px] h-[231px]" />
      <div className="mt-[30px]" />
      <div className="w-[60px] h-[1px] bg-white" />
      <div className="mt-[29px]" />
      <div className="flex items-center gap-2">
        <span className="text-[#FAEECE] text-[26px] font-extrabold">SKY미래연</span>
        <span className="text-white text-[26px] font-extrabold">수학학원</span>
      </div>
      <div className="mt-[30px]" />
      <div className="flex items-center gap-2">
        <span className="text-white text-[36px] font-black">Coming Soon</span>
        {/* <span className="text-white text-[18px] font-black">금요일 · 19:00</span> */}
      </div>
      <div className="mt-[60px]" />
      <button
        onClick={handleButtonClick}
        className="w-full max-w-md h-[60px] bg-white text-black font-bold rounded-2xl text-lg"
      >
        더 알아보기
      </button>
      <div className="mt-[84px]" />
      <img src={logoSky} alt="Logo Sky" className="w-[142px] h-[19px]" />

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" onClick={() => setShowModal(false)}>
          <div className="bg-white p-6 rounded-2xl w-full max-w-md relative" onClick={(e) => e.stopPropagation()}>
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-4 right-4 text-gray-500"
            >
              <X size={24} />
            </button>
            <h2 className="text-2xl font-bold mb-4">예비고1 설명회</h2>
            
            {/* 장소 정보 */}
            <div className="mb-4">
              <div className="flex items-start mb-1">
                <span className="text-[#231F20] text-[14px] font-bold mr-2">장소</span>
                <span className="text-[#231F20] text-[14px]">
                  노원구 중계로 206 3층 
                  <span className="text-[#231F20] text-[10px] font-normal ml-1">
                    (IBK 기업은행 건물)
                  </span>
                </span>
              </div>
              
              {/* 일시 정보 */}
              <div className="flex items-center mb-1">
                <span className="text-[#231F20] text-[14px] font-bold mr-2">일시</span>
                <span className="text-[#231F20] text-[14px]">11월 01일 금요일 · 19:00</span>
              </div>
              
              {/* 주차 정보 */}
              <span className="text-[#231F20] text-[10px] font-normal ml-9">
                *2시간 무료 주차 가능 (위치: 유경데파트 지하주차장)
              </span>
            </div>

            <form onSubmit={handleReservation} className="space-y-4">
              <InputField
                icon={<User />}
                id="name"
                label="이름"
                placeholder="이름을 입력해 주세요"
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={nameError}
              />
              <InputField
                icon={<Phone />}
                id="phone"
                label="연락처"
                placeholder="연락처를 입력해 주세요"
                value={phone}
                onChange={handlePhoneChange}
                maxLength={15}
                error={phoneError}
              />
              <button
                type="submit"
                className={`w-full h-[60px] text-white rounded-2xl font-bold text-lg ${
                  name && phone ? 'bg-[#4ABC85]' : 'bg-[#4ABC85] opacity-40 cursor-not-allowed'
                }`}
                disabled={!name || !phone}
              >
                신청하기
              </button>
            </form>
          </div>
        </div>
      )}

      {showConfirmModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-2xl w-full max-w-md">
            <h2 className="text-2xl font-bold">알림</h2>
            <div className="mt-[35px]" />
            <p className="text-center mb-[20px]">예비고1 설명회가 예약되었어요</p>
            <button
              onClick={() => setShowConfirmModal(false)}
              className="w-full h-[60px] bg-[#4ABC85] text-white rounded-2xl font-bold text-lg"
            >
              확인
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const InputField = ({ icon, id, label, placeholder, value, onChange, maxLength, error }) => (
  <div className="space-y-2">
    <label htmlFor={id} className="block text-sm font-medium text-[#231F20]">{label}</label>
    <div className="relative">
      {icon && React.cloneElement(icon, { 
        className: `absolute left-3 top-1/2 transform -translate-y-1/2 ${value ? 'text-gray-900' : 'text-gray-400'}`,
        size: 20 
      })}
      <input
        id={id}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        className={`w-full h-[60px] ${icon ? 'pl-10' : 'pl-3'} pr-3 bg-[#EEEEEE] border border-[#EEEEEE] rounded-2xl text-sm focus:outline-none focus:border-[#4ABC85] focus:bg-opacity-10 focus:bg-[#4ABC85] placeholder-gray-400 focus:ring-[#4ABC85] caret-[#4ABC85] ${error ? 'border-red-500' : ''}`}
      />
    </div>
    {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
  </div>
);

export default PreReservationPage;
