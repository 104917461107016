import React, { useEffect, useRef, useState } from 'react';
import { Share, CreditCard } from 'lucide-react';
import Header from '../components/Header';
import logoAnalysis from '../assets/logo_analysis.png';

function ResultPage() {
  const canvasRef = useRef(null);
  const progressCanvasRef = useRef(null);
  const [graphData, setGraphData] = useState({
    position: 3,
    data: [
      { grade: 4, time: 50 },
      { grade: 3, time: 100 },
      { grade: 2, time: 150 },
      { grade: 1, time: 250 }
    ]
  });
  const [inputData, setInputData] = useState(JSON.stringify({
    position: 3,
    data: [
      { grade: 4, time: 50 },
      { grade: 3, time: 100 },
      { grade: 2, time: 150 },
      { grade: 1, time: 250 }
    ]
  }, null, 2));
  const [percentileValue, setPercentileValue] = useState("22");
  const normalDistRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = 600;
    const height = 400;
    
    ctx.clearRect(0, 0, width, height);
    
    const myPosition = graphData.position;
    const visibleBars = [];
    
    // 항상 4개의 막대가 보이도록 구성
    if (myPosition === 1) {
      // position이 1일 때: 3등급(0) - 2등급(0) - 1등급(0) - 내위치
      visibleBars.push(
        { grade: 3, time: 0, isMyPosition: false },
        { grade: 2, time: 0, isMyPosition: false },
        { grade: 1, time: 0, isMyPosition: false },
        { grade: myPosition, time: null, isMyPosition: true }
      );
    } else if (myPosition === 2) {
      // position이 2일 때: 4등급 - 3등급 - 내위치(2등급) - 1등급
      visibleBars.push(
        { grade: 4, time: graphData.data.find(d => d.grade === 4)?.time || 0, isMyPosition: false },
        { grade: 3, time: graphData.data.find(d => d.grade === 3)?.time || 0, isMyPosition: false },
        { grade: myPosition, time: null, isMyPosition: true },
        { grade: 1, time: graphData.data.find(d => d.grade === 1)?.time || 0, isMyPosition: false }
      );
    } else if (myPosition === 3) {
      // position이 3일 때: 4등급 - 내위치(3등급) - 2등급 - 1등급
      visibleBars.push(
        { grade: 4, time: graphData.data.find(d => d.grade === 4)?.time || 0, isMyPosition: false },
        { grade: myPosition, time: null, isMyPosition: true },
        { grade: 2, time: graphData.data.find(d => d.grade === 2)?.time || 0, isMyPosition: false },
        { grade: 1, time: graphData.data.find(d => d.grade === 1)?.time || 0, isMyPosition: false }
      );
    } else {
      // position이 4일 때: 내위치(4등급) - 3등급 - 2등급 - 1등급
      visibleBars.push(
        { grade: myPosition, time: null, isMyPosition: true },
        { grade: 3, time: graphData.data.find(d => d.grade === 3)?.time || 0, isMyPosition: false },
        { grade: 2, time: graphData.data.find(d => d.grade === 2)?.time || 0, isMyPosition: false },
        { grade: 1, time: graphData.data.find(d => d.grade === 1)?.time || 0, isMyPosition: false }
      );
    }
    
    // 그래프 그리기 로직
    const barWidth = 80;
    const spacing = 60;
    const totalBars = visibleBars.length;
    const totalWidth = (barWidth * totalBars) + (spacing * (totalBars - 1));
    const startX = (width - totalWidth) / 2;
    
    // 최대 Y값 찾기 (내 위치 제외)
    const maxY = Math.max(...visibleBars.filter(bar => !bar.isMyPosition).map(bar => bar.time));
    
    // 막대 그리기
    visibleBars.forEach((bar, index) => {
      const x = startX + (index * (barWidth + spacing));
      const value = bar.time;
      
      // 내 위치 이전의 시간 값들의 합 계산
      const myPositionIndex = visibleBars.findIndex(b => b.isMyPosition);
      const beforeMyPosition = visibleBars.slice(0, myPositionIndex);
      const totalBeforeTime = beforeMyPosition.reduce((sum, bar) => sum + bar.time, 0);
      
      // 막대 높이 계산 수정
      let normalizedHeight;
      if (bar.isMyPosition) {
        // 내 위치는 기본 크기의 2배
        normalizedHeight = 70 * 2;
      } else {
        if (index > myPositionIndex) {
          // 내 위치보다 오른쪽에 있는 막대들
          const rightBars = visibleBars.slice(myPositionIndex + 1);
          const maxTimeBar = rightBars[rightBars.length - 1];
          
          if (bar === maxTimeBar) {
            normalizedHeight = 250;
          } else {
            normalizedHeight = (250 / maxTimeBar.time) * bar.time;
          }
        } else {
          // 내 위치보다 왼쪽에 있는 막대는 기본 크기
          normalizedHeight = 70;
        }
      }
      
      console.log("막대 정보:", bar.grade, normalizedHeight, value);  // 디버깅용
      
      const y = height - normalizedHeight - 100;
      
      // 막대 그리기
      ctx.beginPath();
      if (bar.isMyPosition) {
        ctx.fillStyle = '#4ABC85'; // 내 위치 막대
      } else {
        // 내 위치 바로 다음 막대 찾기
        const myPositionIndex = visibleBars.findIndex(b => b.isMyPosition);
        const isNextToMyPosition = index === myPositionIndex + 1;
        
        if (isNextToMyPosition) {
          ctx.fillStyle = 'rgba(74, 188, 133, 0.4)'; // #4ABC85 with 40% opacity
        } else {
          ctx.fillStyle = '#EEEEEE';
        }
      }
      
      // 둥근 모서리 막대 그리기 (y 위치 조정됨)
      const radius = 20;
      ctx.moveTo(x + radius, y);
      ctx.lineTo(x + barWidth - radius, y);
      ctx.quadraticCurveTo(x + barWidth, y, x + barWidth, y + radius);
      ctx.lineTo(x + barWidth, y + normalizedHeight - radius);
      ctx.quadraticCurveTo(x + barWidth, y + normalizedHeight, x + barWidth - radius, y + normalizedHeight);
      ctx.lineTo(x + radius, y + normalizedHeight);
      ctx.quadraticCurveTo(x, y + normalizedHeight, x, y + normalizedHeight - radius);
      ctx.lineTo(x, y + radius);
      ctx.quadraticCurveTo(x, y, x + radius, y);
      ctx.fill();
      
      // y값 표시 (시간 단위 추가) - 내 위치가 아니고, 오른쪽에 있는 막대만 표시
      if (!bar.isMyPosition && value !== null && value > 0) {
        const myPositionIndex = visibleBars.findIndex(b => b.isMyPosition);
        // 내 위치보다 오른쪽에 있는 막대만 시간 표시
        if (index > myPositionIndex) {
          ctx.fillStyle = '#231F20';
          ctx.font = '24px sans-serif';
          ctx.textAlign = 'center';
          ctx.fillText(`${value}시간`, x + barWidth/2, y - 20);
        }
      }
      
      // x축 라벨 (막대 아래)
      const label = bar.isMyPosition ? '내 위치' : `${bar.grade}등급`;
      ctx.fillStyle = '#231F20';
      ctx.font = '24px sans-serif';
      ctx.textAlign = 'center';
      ctx.fillText(label, x + barWidth/2, height - 30);
    });
  }, [graphData]);

  useEffect(() => {
    const canvas = progressCanvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = 540;
    const height = 80;
    
    // Canvas 초기화
    ctx.clearRect(0, 0, width, height);
    
    // 전체 time 합계 계산 (내 위치 제외)
    const totalTime = graphData.data
      .filter(item => item.grade !== graphData.position)
      .reduce((sum, item) => sum + item.time, 0);
    
    // 내 위치 바로 앞의 등급(position - 1) time 찾기
    const targetTime = graphData.data.find(item => item.grade === graphData.position - 1)?.time || 0;
    
    // 진행률 계산 (정수부분만)
    const progressPercent = Math.floor((targetTime / totalTime) * 100);
    
    // 배경 바 그리기 (회색)
    ctx.beginPath();
    ctx.fillStyle = '#EEEEEE';
    ctx.roundRect(0, 0, width, height, 32);
    ctx.fill();
    
    // 진행률 바 그리기 (초록색)
    ctx.beginPath();
    ctx.fillStyle = '#4ABC85';
    const progressWidth = (width * progressPercent) / 100;
    ctx.roundRect(0, 0, progressWidth, height, 16);
    ctx.fill();
    
    // 진행률 텍스트 그리기
    ctx.fillStyle = '#FFFFFF';
    ctx.textAlign = 'center';
    
    const textX = progressWidth / 2;
    const textY = height / 2 + 16;
    
    // 숫자 부분 (24px, bold)
    ctx.font = 'bold 48px sans-serif';
    ctx.fillText(progressPercent, textX - 20, textY);
    
    // % 기호 부분 (12px, normal)
    ctx.font = '24px sans-serif';
    ctx.fillText('%', textX + 20, textY);
  }, [graphData]);

  useEffect(() => {
    const canvas = normalDistRef.current;
    const ctx = canvas.getContext('2d');
    const width = 768;
    const height = 520;

    // Canvas 초기화
    ctx.clearRect(0, 0, width, height);

    // 점의 X 좌표 계산 (6~94% 범위로 제한)
    const limitedPercentile = Math.min(94, Math.max(6, parseInt(percentileValue)));
    const pointX = width - (width * limitedPercentile / 100);

    // 정규분포 곡선 그리기 (y값들을 60px 아래로 이동)
    ctx.beginPath();
    ctx.moveTo(40, 480);
    
    // 곡선 그리기
    ctx.bezierCurveTo(
      192, 480,
      192, 160,
      384, 160
    );
    
    ctx.bezierCurveTo(
      576, 160,
      576, 480,
      728, 480
    );

    // 영역 채우기를  경로 설정
    ctx.lineTo(728, 480);
    ctx.lineTo(40, 480);
    
    // 배경 채우기
    ctx.fillStyle = 'rgba(74, 188, 133, 0.1)';
    ctx.fill();
    
    // 선 그리기
    ctx.beginPath();
    ctx.moveTo(40, 480);
    ctx.bezierCurveTo(
      192, 480,
      192, 160,
      384, 160
    );
    ctx.bezierCurveTo(
      576, 160,
      576, 480,
      728, 480
    );
    ctx.strokeStyle = '#4ABC85';
    ctx.lineWidth = 5;
    ctx.stroke();

    // 베지어 곡선 위의 점 계산
    const getBezierPoint = (t) => {
      const isLeft = pointX <= width/2;
      
      let p0, p1, p2, p3;
      
      if (isLeft) {
        // 50% 미만일 때
        p0 = { x: 48, y: 480 };
        p1 = { x: 192, y: 480 };
        p2 = { x: 192, y: 160 };
        p3 = { x: 384, y: 160 };
      } else {
        // 50% 이상일 때 (원본)
        p0 = { x: 384, y: 160 };
        p1 = { x: 576, y: 160 };
        p2 = { x: 576, y: 480 };
        p3 = { x: 728, y: 480 };
      }
      
      // 베지어 곡선 계산
      const x = Math.pow(1-t, 3) * p0.x + 
                3 * Math.pow(1-t, 2) * t * p1.x + 
                3 * (1-t) * Math.pow(t, 2) * p2.x + 
                Math.pow(t, 3) * p3.x;
                
      const y = Math.pow(1-t, 3) * p0.y + 
                3 * Math.pow(1-t, 2) * t * p1.y + 
                3 * (1-t) * Math.pow(t, 2) * p2.y + 
                Math.pow(t, 3) * p3.y;
                
      return { x, y };
    };

    // 이진 탐색으로 가장 가까운 t 값 찾기 (원본 코드 그대로 유지)
    let left = 0;
    let right = 1;
    let bestT = 0.5;
    let bestDist = Infinity;

    for (let i = 0; i < 10; i++) {
      const t1 = left + (right - left) / 3;
      const t2 = right - (right - left) / 3;
      
      const p1 = getBezierPoint(t1);
      const p2 = getBezierPoint(t2);
      
      const dist1 = Math.abs(p1.x - pointX);
      const dist2 = Math.abs(p2.x - pointX);
      
      if (dist1 < bestDist) {
        bestT = t1;
        bestDist = dist1;
      }
      if (dist2 < bestDist) {
        bestT = t2;
        bestDist = dist2;
      }
      
      if (dist1 < dist2) {
        right = t2;
      } else {
        left = t1;
      }
    }

    const point = getBezierPoint(bestT);

    // 위치 표시 점 그리기
    ctx.beginPath();
    ctx.arc(pointX, point.y, 16, 0, Math.PI * 2);
    ctx.fillStyle = '#4ABC85';
    ctx.fill();

    // 상위 % 텍스트 박스 그리기
    const boxWidth = 120;
    const boxHeight = 60;
    const boxX = pointX - boxWidth / 2;
    const boxY = point.y - 80;

    // 박스 배경
    ctx.fillStyle = '#4ABC85';
    ctx.beginPath();
    ctx.roundRect(boxX, boxY, boxWidth, boxHeight, 10);
    ctx.fill();

    // 텍스트 그리기
    ctx.fillStyle = '#FFFFFF';
    ctx.font = '20px sans-serif';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(`상위 ${percentileValue}%`, pointX, boxY + boxHeight/2);

  }, [percentileValue]);

  const handleDataInput = (e) => {
    setInputData(e.target.value);
  };

  const handleApplyData = () => {
    try {
      const newData = JSON.parse(inputData);
      setGraphData(newData);
    } catch (error) {
      console.error('Invalid JSON format');
    }
  };

  const handlePercentileChange = (e) => {
    const value = e.target.value;
    if (value === "" || (parseInt(value) >= 0 && parseInt(value) <= 100)) {
      setPercentileValue(value);
    }
  };

  // 기존의 365가 있던 부분을 수정
  const getGoalTime = (graphData) => {
    // 내 위치보다 앞선 등급들의 시간 합계 계산
    return graphData.data
      .filter(item => item.grade < graphData.position)
      .reduce((sum, item) => sum + item.time, 0);
  };

  // 목표 시간을 표시하는 부분
  const goalTimeText = `목표 ${getGoalTime(graphData)}시간`;

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Header />
      <main className="flex-grow flex flex-col items-center pt-[12px]">
        {/* 로고 추 */}
        <img 
          src={logoAnalysis}
          alt="Analysis Logo" 
          className="w-[121px] h-[26px] mb-[40px]"
        />
        
        {/* 카드 4개 */}
        <div className="w-[384px] space-y-[20px] mb-[40px]">
          <div className="w-full border border-gray-200 rounded-2xl p-4">
            <p className="text-center text-[12px] font-semibold mb-[14px]">
              1등급까지 이만큼 남았어요
            </p>
            <div className="flex justify-center items-center">
              <span className="text-[48px] font-bold text-[#231F20]">{getGoalTime(graphData)}</span>
              <span className="text-[12px] font-semibold text-[#A3A1A2] mt-[24px]">시간</span>
            </div>
            
            {/* 그래프 가 */}
            <div className="mt-[30px]">
              <canvas 
                ref={canvasRef}
                width={600}
                height={400}
                className="w-full"
              />
            </div>
            
            {/* 두 번째 그래프 */}
            <div className="mt-[40px]">
              <p className="text-[12px] font-semibold text-[#231F20] text-left mb-2 pl-[30px]">
                목표 도달량
              </p>
              <div className="flex justify-center">
                <canvas 
                  ref={progressCanvasRef}
                  width={540}
                  height={80}
                  className="w-[290px]"
                />
              </div>
            </div>
          </div>
          <div className="w-full border border-gray-200 rounded-2xl p-4 flex flex-col items-center">
            <p className="text-[12px] font-semibold text-[#231F20] text-center">
              지금 수강하면 이만큼 단축할 수 있어요
            </p>
            <div className="flex items-center mt-[8px]">
              <span className="text-[32px] font-bold text-[#231F20]">180</span>
              <span className="text-[12px] font-semibold text-[#A3A1A2] mt-[16px] ml-[2px]">시간</span>
            </div>
          </div>
          {/* 세 번째 드 */}
          <div className="w-full border border-gray-200 rounded-2xl p-4">
            <p className="text-center text-[12px] font-semibold text-[#231F20] mb-[14px]">
              내 실력에 대한 백분위를 확인해보세요
            </p>
            <canvas
              ref={normalDistRef}
              width={768}
              height={520}
              className="w-full"
            />
          </div>
          <div className="w-full border border-gray-200 rounded-2xl p-4">
            <p className="text-[12px] font-semibold text-[#231F20] mb-[10px]">
              이 부분은 보완이 필요해요
            </p>
            
            {/* 카드 4-1 */}
            <div className="h-[73px] bg-[#F8F8F8] rounded-2xl p-4 mb-[10px]">
              <div className="flex items-center gap-2 mb-1">
                <div className="h-[20px] px-2 flex items-center bg-[#4ABC85] rounded-[10px]">
                  <span className="text-[8px] font-semibold text-white">공통수학 1</span>
                </div>
                <span className="text-[12px] font-medium text-[#7B7979]">다항식의 연산</span>
              </div>
              <p className="text-[14px] font-semibold text-[#231F20]">
                다항식의 덧셈과 뺄셈
              </p>
            </div>

            {/* 카드 4-2 */}
            <div className="h-[73px] bg-[#F8F8F8] rounded-2xl p-4 mb-[10px]">
              <div className="flex items-center gap-2 mb-1">
                <div className="h-[20px] px-2 flex items-center bg-[#4ABC85] rounded-[10px]">
                  <span className="text-[8px] font-semibold text-white">공통수학 1</span>
                </div>
                <span className="text-[12px] font-medium text-[#7B7979]">다항식의 연산</span>
              </div>
              <p className="text-[14px] font-semibold text-[#231F20]">
                곱셈공식을 이용한 식의 전개
              </p>
            </div>

            {/* 카드 4-3 */}
            <div className="h-[73px] bg-[#F8F8F8] rounded-2xl p-4">
              <div className="flex items-center gap-2 mb-1">
                <div className="h-[20px] px-2 flex items-center bg-[#4ABC85] rounded-[10px]">
                  <span className="text-[8px] font-semibold text-white">공통수학 1</span>
                </div>
                <span className="text-[12px] font-medium text-[#7B7979]">항등식과 나머지 정리</span>
              </div>
              <p className="text-[14px] font-semibold text-[#231F20]">
                계수비교법
              </p>
            </div>
          </div>

        {/* 버 그룹 */}
        <div className="w-[384px] flex justify-between space-x-[27px]">
          <button className="w-[119px] h-[60px] flex items-center justify-center space-x-2 bg-[#EEEEEE] text-[#7B7979] rounded-lg">
            <Share size={20} />
            <span className="font-bold text-sm">공유하기</span>
          </button>
          <button className="w-[238px] h-[60px] flex items-center justify-center space-x-2 bg-[#4ABC85] text-white rounded-lg">
            <CreditCard size={20} />
            <span className="font-bold text-sm">수강 등록하</span>
          </button>
        </div>

          {/* 데이터 입력 필드 */}
          <div className="w-[384px] mt-4">
            <textarea
              className="w-full p-2 border rounded"
              value={inputData}
              onChange={handleDataInput}
              rows={6}
            />
            <button
              onClick={handleApplyData}
              className="w-full mt-2 p-2 bg-[#4ABC85] text-white rounded font-bold"
            >
              데이터 반영하기
            </button>

            {/* 정규분포 입력 필드 - 여기로 이동 */}
            <div className="mt-4 flex gap-2">
              <input
                type="number"
                min="0"
                max="100"
                value={percentileValue}
                onChange={handlePercentileChange}
                className="border rounded p-2 w-24"
              />
              <button
                onClick={() => setPercentileValue(percentileValue)}
                className="bg-[#4ABC85] text-white px-4 py-2 rounded"
              >
                적용
              </button>
            </div>
          </div>
        </div>


      </main>
    </div>
  );
}

export default ResultPage;